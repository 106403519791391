var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: `key_${_vm.title}_${_vm.key}` },
    [
      _c("CourseSubHeader", {
        attrs: {
          "show-marking-period-picker": true,
          "show-google-menu": true,
          "show-google-rosters-modal": _vm.showGoogleRostersModal,
        },
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function () {
              return [
                _vm.canManageStudents
                  ? _c(
                      "span",
                      [
                        _c(
                          "a",
                          {
                            staticClass:
                              "btn kt-subheader__btn-secondary btn-bold btn-upper mr-2",
                            attrs: {
                              id: "addStudent-popover",
                              href: "#",
                              tabindex: "0",
                              "data-toggle": "popover",
                              "data-trigger": "focus",
                              type: "button",
                            },
                          },
                          [_vm._v(" Add Student ")]
                        ),
                        _c(
                          "b-popover",
                          {
                            attrs: {
                              target: "addStudent-popover",
                              triggers: "focus",
                              placement: "bottom",
                              "custom-class": "popover",
                            },
                            model: {
                              value: _vm.showAddStudent,
                              callback: function ($$v) {
                                _vm.showAddStudent = $$v
                              },
                              expression: "showAddStudent",
                            },
                          },
                          [
                            _c("div", { staticClass: "pt-3 add-student" }, [
                              _c("form", { staticClass: "kt-form" }, [
                                _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "div",
                                    { staticClass: "input-group" },
                                    [
                                      _c("vue-tags-input", {
                                        attrs: {
                                          placeholder: "Search for student(s)",
                                          tags: _vm.newStudents,
                                          "autocomplete-items":
                                            _vm.addableStudents,
                                          "add-only-from-autocomplete": true,
                                        },
                                        on: {
                                          "tags-changed": (newFilters) =>
                                            (_vm.newStudents = newFilters),
                                        },
                                        model: {
                                          value: _vm.filter,
                                          callback: function ($$v) {
                                            _vm.filter = $$v
                                          },
                                          expression: "filter",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "input-group-append" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-primary pb-0 pt-0",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.addStudentToCourse.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Add ")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-bold kt-margin-l-10",
                            class: {
                              "btn-label-primary": _vm.sortBy === "Last Name",
                              "btn-label-success": _vm.sortBy === "First Name",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                _vm.sortBy === "Last Name"
                                  ? (_vm.sortBy = "First Name")
                                  : (_vm.sortBy = "Last Name")
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.sortBy) + " ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.isPortfolioReady
        ? _c(
            "div",
            {
              staticClass:
                "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
            },
            [
              _c(
                "div",
                { staticClass: "row" },
                _vm._l(_vm.students, function (student) {
                  return _c(
                    "div",
                    {
                      key: `qp_${student.studentEnrollmentId}`,
                      staticClass: "col-xl-3 col-lg-4 col-md-6 col-xs-12",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "kt-portlet kt-portlet--height-fluid",
                          staticStyle: { position: "relative" },
                        },
                        [
                          _vm.canManageStudents
                            ? _c(
                                "div",
                                {
                                  class: {
                                    "top-right":
                                      !student.googleStudent ||
                                      _vm.isGoogleDisabled,
                                    "top-left":
                                      student.googleStudent &&
                                      !_vm.isGoogleDisabled,
                                  },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-label-danger btn-sm btn-upper btn-bold",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.removeStudentFromCourse(
                                            student
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" Remove ")]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass:
                                "kt-portlet__body kt-ribbon kt-ribbon--flag kt-ribbon--ver kt-portlet__body--fit-y kt-margin-b-40 kt-ribbon--primary",
                            },
                            [
                              student.googleStudent && !_vm.isGoogleDisabled
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "kt-ribbon__target",
                                      staticStyle: {
                                        top: "0",
                                        right: "20px",
                                        height: "45px",
                                      },
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "kt-ribbon__inner",
                                      }),
                                      _c("i", {
                                        staticClass:
                                          "socicon-google mt-1 kt-font-light",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "kt-widget kt-widget--user-profile-4",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "kt-widget__head mt-5 mb-3",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "kt-widget__media" },
                                        [
                                          _c(
                                            "UserAvatar",
                                            {
                                              staticClass: "kt-widget__img_sg",
                                              attrs: { "avatar-user": student },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "kt-badge kt-badge--xl",
                                                  class: {
                                                    "kt-badge--danger":
                                                      !student.linked,
                                                    "kt-badge--primary":
                                                      student.linked,
                                                  },
                                                  staticStyle: {
                                                    width: "90px",
                                                    height: "90px",
                                                    "font-size": "2rem",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        student.lastName.substring(
                                                          0,
                                                          1
                                                        )
                                                      ) +
                                                      _vm._s(
                                                        student.firstName.substring(
                                                          0,
                                                          1
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "kt-widget__content" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "kt-widget__section pt-4",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "kt-widget__footer",
                                                },
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      staticClass:
                                                        "kt-widget__username",
                                                      attrs: {
                                                        title:
                                                          "Open Student Portfolio",
                                                        to: {
                                                          name: "StudentInfo",
                                                          params: {
                                                            studentEnrollmentId:
                                                              student.studentEnrollmentId,
                                                          },
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            student.lastName
                                                          ) +
                                                          ", " +
                                                          _vm._s(
                                                            student.firstName
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "kt-widget__description",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$_utilMixins_format_ext_student_id(
                                                                student.extStudentId
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "mt-3" },
                                                    [
                                                      _c(
                                                        "router-link",
                                                        {
                                                          staticClass:
                                                            "d-flex justify-content-center flex-wrap",
                                                          attrs: {
                                                            title:
                                                              "Open Student Course Info",
                                                            to: {
                                                              name: "StudentCourseOverview",
                                                              params: {
                                                                studentEnrollmentId:
                                                                  student.studentEnrollmentId,
                                                                courseSectionId:
                                                                  student.courseSectionId,
                                                              },
                                                            },
                                                          },
                                                        },
                                                        _vm._l(
                                                          student.markingPeriodAverages,
                                                          function (avg) {
                                                            return _c(
                                                              "StudentCourseAverage",
                                                              {
                                                                key: `css_${avg.courseSectionStudentId}_${avg.schoolTermMarkingPeriodId}`,
                                                                staticClass:
                                                                  "m-3",
                                                                attrs: {
                                                                  color:
                                                                    avg.color,
                                                                  size: "lg",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "display-value",
                                                                        fn: function () {
                                                                          return [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$_utilMixins_decimal_to_percent(
                                                                                    avg.markingPeriodAverage,
                                                                                    true,
                                                                                    0
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                      {
                                                                        key: "sub-value",
                                                                        fn: function () {
                                                                          return [
                                                                            _vm._v(
                                                                              " MP " +
                                                                                _vm._s(
                                                                                  avg
                                                                                    .markingPeriod
                                                                                    .markingPeriod
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              !student.googleEmail &&
                                              !_vm.isGoogleDisabled
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "kt-widget__description mb-3",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "kt-font-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Missing google email address "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "kt-widget__button mt-3",
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        student.extCourseSectionId
                                                      ) + " "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              student.courseSectionName
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "kt-widget__description",
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              student.courseSectionName
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              !_vm.course.googleCourseId &&
                                              !_vm.isGoogleDisabled
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "kt-widget__description kt-font-danger mt-2",
                                                    },
                                                    [_vm._m(0, true)]
                                                  )
                                                : _vm._e(),
                                              _vm.course.googleCourseId &&
                                              student.googleStudent == null &&
                                              !_vm.isGoogleDisabled
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "kt-widget__description kt-font-danger mt-2",
                                                    },
                                                    [_vm._m(1, true)]
                                                  )
                                                : _vm._e(),
                                              _vm.course.googleCourseId &&
                                              student.googleStudent &&
                                              !_vm.isGoogleDisabled
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "kt-widget__description kt-font-primary mt-2",
                                                    },
                                                    [_vm._m(2, true)]
                                                  )
                                                : _vm._e(),
                                              _vm.course.googleCourseId &&
                                              !_vm.isGoogleDisabled
                                                ? [
                                                    student.googleEmail
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "kt-widget__description email-container",
                                                            class: [
                                                              student.googleStudent
                                                                ? ""
                                                                : "kt-font-danger",
                                                            ],
                                                          },
                                                          [
                                                            _c("span", {
                                                              domProps: {
                                                                innerHTML:
                                                                  _vm._s(
                                                                    _vm.$_utilMixins_format_email(
                                                                      student.googleEmail
                                                                    )
                                                                  ),
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "kt-widget__description kt-font-danger",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Missing Google Email Address "
                                                            ),
                                                          ]
                                                        ),
                                                  ]
                                                : _vm._e(),
                                              student.courseSection
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "kt-widget__button mt-4",
                                                    },
                                                    [
                                                      _c(
                                                        "router-link",
                                                        {
                                                          staticClass:
                                                            "btn btn-wide btn-upper w-100",
                                                          class:
                                                            student
                                                              .courseSection
                                                              .courseSectionColor,
                                                          attrs: {
                                                            to: {
                                                              name: "StudentCourseOverview",
                                                              params: {
                                                                studentEnrollmentId:
                                                                  student.studentEnrollmentId,
                                                                courseSectionId:
                                                                  student.courseSectionId,
                                                              },
                                                            },
                                                          },
                                                        },
                                                        [
                                                          student.courseSection
                                                            .courseSectionIcon
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      student
                                                                        .courseSection
                                                                        .courseSectionIcon
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                student.courseSectionName
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          )
        : _vm._e(),
      _c("b-modal", {
        attrs: { size: "lg", variant: "primary", centered: "" },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function () {
              return [
                _c("h5", { staticClass: "modal-title" }, [
                  _vm._v(" Populate Google Classroom Rosters "),
                ]),
                _c("button", {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.close.apply(null, arguments)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "default",
            fn: function () {
              return [
                _vm.saving
                  ? _c("div", { staticClass: "modal-body" }, [
                      !_vm.bulkRosteringCompleted
                        ? _c(
                            "div",
                            { staticClass: "form-group form-group-last" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "alert alert-secondary",
                                  attrs: { role: "alert" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "alert-icon" },
                                    [
                                      _c("b-spinner", {
                                        staticClass: "m-3",
                                        staticStyle: {
                                          width: "3rem",
                                          height: "3rem",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("div", { staticClass: "alert-text" }, [
                                    _vm._v(
                                      " Please wait while we ask google to perform the roster additions... "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm.rosterErrors.length > 0
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass: "alert alert-danger",
                                attrs: { role: "alert" },
                              },
                              [
                                _c("div", { staticClass: "alert-icon" }, [
                                  _c("i", { staticClass: "flaticon-warning" }),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "alert-text",
                                    staticStyle: { "line-height": "2rem" },
                                  },
                                  [
                                    _vm._v(
                                      " If you are having trouble adding students to google classroom, you can always instruct the students to enroll themselves to the class by logging into "
                                    ),
                                    _c("code", [
                                      _vm._v("classroom.google.com"),
                                    ]),
                                    _vm._v(" and clicking the "),
                                    _c("strong", [_vm._v("+")]),
                                    _vm._v(
                                      " icon in the upper right, and entering the class code: "
                                    ),
                                    _c(
                                      "code",
                                      { staticClass: "kt-font-lg mx-3" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.googleCourse.enrollmentCode
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _c("ul", [
                        _c("li", [
                          _c("span", { staticClass: "kt-font-brand" }, [
                            _c(
                              "span",
                              { staticClass: "number-label kt-font-bolder" },
                              [_vm._v(_vm._s(_vm.missingStudents.length))]
                            ),
                            _vm._v(" Requesting Admission "),
                          ]),
                        ]),
                        _c("li", [
                          _c("span", { staticClass: "kt-font-success" }, [
                            _c(
                              "span",
                              { staticClass: "number-label kt-font-bolder" },
                              [_vm._v(_vm._s(_vm.rosterAdditions.length))]
                            ),
                            _vm._v(" Successful Admissions "),
                          ]),
                        ]),
                        _c("li", [
                          _c("span", { staticClass: "kt-font-danger" }, [
                            _c(
                              "span",
                              { staticClass: "number-label kt-font-bolder" },
                              [_vm._v(_vm._s(_vm.rosterErrors.length))]
                            ),
                            _vm._v(" Rejected Admissions "),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "form-group mb-3" }, [
                        _c("label", { staticClass: "kt-font-bold" }, [
                          _vm._v(" Google Classroom Rostering Logs "),
                        ]),
                      ]),
                      _c("div", { staticClass: "row log-container px-4" }, [
                        _vm.rosterAdditions.length > 0
                          ? _c("div", { staticClass: "form-group" }, [
                              _c(
                                "div",
                                { staticClass: "kt-checkbox-list" },
                                _vm._l(_vm.rosterAdditions, function (student) {
                                  return _c(
                                    "label",
                                    {
                                      key: `studentAdd_${student.studentEnrollmentId}`,
                                      staticClass:
                                        "kt-checkbox kt-checkbox--tick kt-checkbox--success",
                                    },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "checkbox",
                                          checked: "checked",
                                        },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(student.googleEmail) +
                                          " was successfully added to the google course. "
                                      ),
                                      _c("span"),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ])
                          : _vm._e(),
                        _vm.rosterErrors.length > 0
                          ? _c("div", { staticClass: "form-group" }, [
                              _c(
                                "div",
                                { staticClass: "kt-checkbox-list" },
                                _vm._l(_vm.rosterErrors, function (student) {
                                  return _c(
                                    "label",
                                    {
                                      key: `studenterr_${student.studentEnrollmentId}`,
                                      staticClass:
                                        "kt-checkbox kt-checkbox--tick kt-checkbox--danger",
                                    },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "checkbox",
                                          checked: "checked",
                                          disabled: "disabled",
                                        },
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(student.googleEmail) +
                                          " - "
                                      ),
                                      _c("code", [_vm._v(_vm._s(student.err))]),
                                      _c("span"),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ])
                  : _c("div", { staticClass: "modal-body" }, [
                      !_vm.schoolGoogleSchoolStaffId
                        ? _c(
                            "div",
                            { staticClass: "form-group form-group-last" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "alert alert-secondary",
                                  attrs: { role: "alert" },
                                },
                                [
                                  _c("div", { staticClass: "alert-icon" }, [
                                    _c("i", {
                                      staticClass:
                                        "flaticon-warning kt-font-brand",
                                    }),
                                  ]),
                                  _c("div", { staticClass: "alert-text" }, [
                                    _vm._v(
                                      " Please select the credentials of a user with administrative google classroom permissions. Only admin accounts can directly add students into a google course. Teacher accounts can only send email invitations, or ask students to use enrollment code "
                                    ),
                                    _c(
                                      "code",
                                      { staticClass: "kt-font-lg mx-3" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.googleCourse.enrollmentCode
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      !_vm.schoolGoogleSchoolStaffId
                        ? _c(
                            "div",
                            { staticClass: "form-group w-100 mb-1 ml-2 mt-1" },
                            [
                              _c("label", [
                                _vm._v(
                                  "Select Administrative Google Classroom User"
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      !_vm.schoolGoogleSchoolStaffId
                        ? _c("div", { staticClass: "col-12" }, [
                            _c("div", { staticClass: "form-group px-3" }, [
                              _c("div", { staticClass: "input-group" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedSchoolStaffId,
                                        expression: "selectedSchoolStaffId",
                                      },
                                    ],
                                    staticClass: "custom-select form-control",
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.selectedSchoolStaffId = $event
                                          .target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                    },
                                  },
                                  [
                                    _c("option", { domProps: { value: 0 } }),
                                    _vm._l(_vm.teachers, function (t, idx) {
                                      return _c(
                                        "option",
                                        {
                                          key: `staff_${t.schoolStaffId}_${idx}`,
                                          domProps: { value: t.schoolStaffId },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(t.googleEmail) + " "
                                          ),
                                        ]
                                      )
                                    }),
                                  ],
                                  2
                                ),
                                _c(
                                  "div",
                                  { staticClass: "input-group-append" },
                                  [
                                    _vm.saving
                                      ? [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "input-group-text d-block",
                                              staticStyle: {
                                                "min-width": "44px",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "kt-spinner kt-spinner--sm kt-spinner--primary d-block",
                                                staticStyle: {
                                                  "margin-top": "10px",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      : !_vm.saving
                                      ? [
                                          _vm.selectedSchoolStaffId
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-success kt-font-bolder",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.addStudentsToGoogleCourse.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" Continue ")]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "input-group-text",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      _vm.showModal = false
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "la la-close",
                                                  }),
                                                ]
                                              ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "alert alert-secondary",
                          attrs: { role: "alert" },
                        },
                        [
                          _c("div", { staticClass: "alert-icon" }, [
                            _c("i", {
                              staticClass:
                                "flaticon-questions-circular-button kt-font-warning",
                            }),
                          ]),
                          _c("div", { staticClass: "alert-text" }, [
                            _vm._v(
                              " In order to add students into the google classroom roster, the student must already have a school managed email account, and that same email address must be associated with the student in SyncGrades. "
                            ),
                          ]),
                        ]
                      ),
                    ]),
              ]
            },
            proxy: true,
          },
          {
            key: "modal-footer",
            fn: function () {
              return [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-label mr-2",
                        class: [
                          _vm.bulkRosteringCompleted
                            ? "btn-label-secondary"
                            : "btn-label-danger",
                        ],
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.close.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              !_vm.bulkRosteringCompleted
                                ? "Cancel Rostering"
                                : "Close"
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _vm.schoolGoogleSchoolStaffId && !_vm.saving
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-label-success mr-2",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.addStudentsToGoogleCourse(true)
                              },
                            },
                          },
                          [_vm._v(" Start Rostering with Admin Credentials ")]
                        )
                      : _vm._e(),
                    !_vm.saving
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-label-primary",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.addStudentsToGoogleCourse(false)
                              },
                            },
                          },
                          [_vm._v(" Start Rostering ")]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.showModal,
          callback: function ($$v) {
            _vm.showModal = $$v
          },
          expression: "showModal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "flaticon-warning-sign mr-2" }),
      _vm._v(" Google Course Not Linked "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "flaticon-warning-sign mr-2" }),
      _vm._v(" Not on Google Roster "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "flaticon2-check-mark mr-2" }),
      _vm._v(" Enrolled "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }