<template>
<div :key="`key_${title}_${key}`">
    <CourseSubHeader
        :show-marking-period-picker="true"
        :show-google-menu="true"
        :show-google-rosters-modal="showGoogleRostersModal"
    >
        <template #buttons>
            <span v-if="canManageStudents">
                <a
                    id="addStudent-popover"
                    href="#"
                    tabindex="0"
                    data-toggle="popover"
                    data-trigger="focus"
                    type="button"
                    class="btn kt-subheader__btn-secondary btn-bold btn-upper mr-2"
                >
                    Add Student
                </a>
                <b-popover
                    v-model:show="showAddStudent"
                    target="addStudent-popover"
                    triggers="focus"
                    placement="bottom"
                    custom-class="popover"
                >
                    <div class="pt-3 add-student">
                        <form class="kt-form">
                            <div class="form-group">
                                <div class="input-group">
                                    <vue-tags-input
                                        v-model="filter"
                                        placeholder="Search for student(s)"
                                        :tags="newStudents"
                                        :autocomplete-items="addableStudents"
                                        :add-only-from-autocomplete="true"
                                        @tags-changed="newFilters => newStudents = newFilters"
                                    />
                                    <div class="input-group-append">
                                        <button
                                            class="btn btn-primary pb-0 pt-0"
                                            type="button"
                                            @click.stop.prevent="addStudentToCourse"
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </b-popover>
                <button
                    class="btn btn-bold kt-margin-l-10"
                    :class="{ 'btn-label-primary': sortBy === 'Last Name', 'btn-label-success': sortBy === 'First Name' }"
                    @click.stop.prevent="sortBy === 'Last Name' ? sortBy = 'First Name' : sortBy = 'Last Name'"
                >
                    {{ sortBy }}
                </button>
            </span>
        </template>
    </CourseSubHeader>

    <div
        v-if="isPortfolioReady"
        class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid"
    >
        <div class="row">
            <div
                v-for="student in students"
                :key="`qp_${student.studentEnrollmentId}`"
                class="col-xl-3 col-lg-4 col-md-6 col-xs-12"
            >
                <div
                    class="kt-portlet kt-portlet--height-fluid"
                    style="position: relative;"
                >
                    <div
                        v-if="canManageStudents"
                        :class="{
                            'top-right': !student.googleStudent || isGoogleDisabled,
                            'top-left': student.googleStudent && !isGoogleDisabled,
                        }"
                    >
                        <button
                            class="btn btn-label-danger btn-sm btn-upper btn-bold"
                            @click.stop.prevent="removeStudentFromCourse(student)"
                        >
                            Remove
                        </button>
                    </div>
                    <div class="kt-portlet__body kt-ribbon kt-ribbon--flag kt-ribbon--ver kt-portlet__body--fit-y kt-margin-b-40 kt-ribbon--primary">
                        <div
                            v-if="student.googleStudent && !isGoogleDisabled"
                            class="kt-ribbon__target"
                            style="top: 0; right: 20px; height: 45px"
                        >
                            <span class="kt-ribbon__inner" />
                            <i class="socicon-google mt-1 kt-font-light" />
                        </div>

                        <div class="kt-widget kt-widget--user-profile-4">
                            <div class="kt-widget__head mt-5 mb-3">
                                <div class="kt-widget__media">
                                    <UserAvatar
                                        class="kt-widget__img_sg"
                                        :avatar-user="student"
                                    >
                                        <div
                                            class="kt-badge kt-badge--xl"
                                            :class="{
                                                'kt-badge--danger': !student.linked,
                                                'kt-badge--primary': student.linked,
                                            }"
                                            style="width: 90px; height: 90px; font-size: 2rem"
                                        >
                                            {{ student.lastName.substring(0, 1) }}{{ student.firstName.substring(0, 1) }}
                                        </div>
                                    </UserAvatar>
                                </div>
                                <div class="kt-widget__content">
                                    <div class="kt-widget__section pt-4">
                                        <div class="kt-widget__footer">
                                            <router-link
                                                title="Open Student Portfolio"
                                                :to="{
                                                    name: 'StudentInfo',
                                                    params: {
                                                        studentEnrollmentId: student.studentEnrollmentId
                                                    },
                                                }"
                                                class="kt-widget__username"
                                            >
                                                {{ student.lastName }}, {{ student.firstName }}
                                            </router-link>

                                            <div class="kt-widget__description">
                                                <span>
                                                    {{ $_utilMixins_format_ext_student_id(student.extStudentId) }}
                                                </span>
                                            </div>

                                            <div class="mt-3">
                                                <router-link
                                                    title="Open Student Course Info"
                                                    :to="{
                                                        name: 'StudentCourseOverview',
                                                        params: {
                                                            studentEnrollmentId: student.studentEnrollmentId,
                                                            courseSectionId: student.courseSectionId,
                                                        }
                                                    }"
                                                    class="d-flex justify-content-center flex-wrap"
                                                >
                                                    <StudentCourseAverage
                                                        v-for="avg in student.markingPeriodAverages"
                                                        :key="`css_${avg.courseSectionStudentId}_${avg.schoolTermMarkingPeriodId}`"
                                                        :color="avg.color"
                                                        class="m-3"
                                                        size="lg"
                                                    >
                                                        <template #display-value>
                                                            {{ $_utilMixins_decimal_to_percent(avg.markingPeriodAverage, true, 0) }}
                                                        </template>
                                                        <template #sub-value>
                                                            MP {{ avg.markingPeriod.markingPeriod }}
                                                        </template>
                                                    </StudentCourseAverage>
                                                </router-link>
                                            </div>
                                        </div>

                                        <div
                                            v-if="!student.googleEmail && !isGoogleDisabled"
                                            class="kt-widget__description mb-3"
                                        >
                                            <span class="kt-font-danger">
                                                Missing google email address
                                            </span>
                                        </div>

                                        <div class="kt-widget__button mt-3">
                                            <span>{{ student.extCourseSectionId }} </span>
                                        </div>

                                        <div
                                            v-if="student.courseSectionName"
                                            class="kt-widget__description"
                                        >
                                            <span>
                                                {{ student.courseSectionName }}
                                            </span>
                                        </div>

                                        <div
                                            v-if="!course.googleCourseId && !isGoogleDisabled"
                                            class="kt-widget__description kt-font-danger mt-2"
                                        >
                                            <span>
                                                <i class="flaticon-warning-sign mr-2" />
                                                Google Course Not Linked
                                            </span>
                                        </div>
                                        <div
                                            v-if="course.googleCourseId && student.googleStudent == null && !isGoogleDisabled"
                                            class="kt-widget__description kt-font-danger mt-2"
                                        >
                                            <span>
                                                <i class="flaticon-warning-sign mr-2" />
                                                Not on Google Roster
                                            </span>
                                        </div>
                                        <div
                                            v-if="course.googleCourseId && student.googleStudent && !isGoogleDisabled"
                                            class="kt-widget__description kt-font-primary mt-2"
                                        >
                                            <span>
                                                <i class="flaticon2-check-mark mr-2" />
                                                Enrolled
                                            </span>
                                        </div>
                                        <template v-if="course.googleCourseId && !isGoogleDisabled">
                                            <div
                                                v-if="student.googleEmail"
                                                class="kt-widget__description email-container"
                                                :class="[
                                                    student.googleStudent ? '' : 'kt-font-danger'
                                                ]"
                                            >
                                                <span v-html="$_utilMixins_format_email(student.googleEmail) " />
                                            </div>
                                            <div
                                                v-else
                                                class="kt-widget__description kt-font-danger"
                                            >
                                                Missing Google Email Address
                                            </div>
                                        </template>

                                        <div
                                            v-if="student.courseSection"
                                            class="kt-widget__button mt-4"
                                        >
                                            <router-link
                                                :to="{
                                                    name: 'StudentCourseOverview',
                                                    params: {
                                                        studentEnrollmentId: student.studentEnrollmentId,
                                                        courseSectionId: student.courseSectionId,
                                                    }
                                                }"
                                                class="btn btn-wide btn-upper w-100"
                                                :class="student.courseSection.courseSectionColor"
                                            >
                                                <span v-if="student.courseSection.courseSectionIcon">
                                                    {{ student.courseSection.courseSectionIcon }}
                                                </span>

                                                {{ student.courseSectionName }}
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <b-modal
        v-model="showModal"
        size="lg"
        variant="primary"
        centered
    >
        <template #modal-header>
            <h5 class="modal-title">
                Populate Google Classroom Rosters
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click.stop.prevent="close"
            />
        </template>
        <template #default>
            <div
                v-if="saving"
                class="modal-body"
            >
                <div
                    v-if="!bulkRosteringCompleted"
                    class="form-group form-group-last"
                >
                    <div
                        class="alert alert-secondary"
                        role="alert"
                    >
                        <div class="alert-icon">
                            <b-spinner
                                style="width: 3rem; height: 3rem;"
                                class="m-3"
                            />
                        </div>
                        <div class="alert-text">
                            Please wait while we ask google to perform the roster additions...
                        </div>
                    </div>
                </div>
                <div v-else-if="rosterErrors.length > 0">
                    <div
                        class="alert alert-danger"
                        role="alert"
                    >
                        <div class="alert-icon">
                            <i class="flaticon-warning" />
                        </div>
                        <div
                            class="alert-text"
                            style="line-height: 2rem"
                        >
                            If you are having trouble adding students to google classroom,
                            you can always instruct the students to enroll themselves to the class by logging into
                            <code>classroom.google.com</code> and clicking the <strong>+</strong> icon in the upper right,
                            and entering the class code: <code class="kt-font-lg mx-3">{{ googleCourse.enrollmentCode }}</code>
                        </div>
                    </div>
                </div>

                <ul>
                    <li>
                        <span class="kt-font-brand">
                            <span class="number-label kt-font-bolder">{{ missingStudents.length }}</span>
                            Requesting Admission
                        </span>
                    </li>
                    <li>
                        <span class="kt-font-success">
                            <span class="number-label kt-font-bolder">{{ rosterAdditions.length }}</span>
                            Successful Admissions
                        </span>
                    </li>
                    <li>
                        <span class="kt-font-danger">
                            <span class="number-label kt-font-bolder">{{ rosterErrors.length }}</span>
                            Rejected Admissions
                        </span>
                    </li>
                </ul>

                <div class="form-group mb-3">
                    <label class="kt-font-bold">
                        Google Classroom Rostering Logs
                    </label>
                </div>
                <div class="row log-container px-4">
                    <div
                        v-if="rosterAdditions.length > 0"
                        class="form-group"
                    >
                        <div class="kt-checkbox-list">
                            <label
                                v-for="student in rosterAdditions"
                                :key="`studentAdd_${student.studentEnrollmentId}`"
                                class="kt-checkbox kt-checkbox--tick kt-checkbox--success"
                            >
                                <input
                                    type="checkbox"
                                    checked="checked"
                                >
                                {{ student.googleEmail }}
                                was successfully added to the google course.
                                <span />
                            </label>
                        </div>
                    </div>

                    <div
                        v-if="rosterErrors.length > 0"
                        class="form-group"
                    >
                        <div class="kt-checkbox-list">
                            <label
                                v-for="student in rosterErrors"
                                :key="`studenterr_${student.studentEnrollmentId}`"
                                class="kt-checkbox kt-checkbox--tick kt-checkbox--danger"
                            >
                                <input
                                    type="checkbox"
                                    checked="checked"
                                    disabled="disabled"
                                >
                                {{ student.googleEmail }} -
                                <code>{{ student.err }}</code>
                                <span />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-else
                class="modal-body"
            >
                <div
                    v-if="!schoolGoogleSchoolStaffId"
                    class="form-group form-group-last"
                >
                    <div
                        class="alert alert-secondary"
                        role="alert"
                    >
                        <div class="alert-icon">
                            <i class="flaticon-warning kt-font-brand" />
                        </div>
                        <div class="alert-text">
                            Please select the credentials of a user with administrative google classroom permissions.
                            Only admin accounts can directly add students into a google course.
                            Teacher accounts can only send email invitations, or ask students to use enrollment code
                            <code class="kt-font-lg mx-3">{{ googleCourse.enrollmentCode }}</code>
                        </div>
                    </div>
                </div>
                <div
                    v-if="!schoolGoogleSchoolStaffId"
                    class="form-group w-100 mb-1 ml-2 mt-1"
                >
                    <label>Select Administrative Google Classroom User</label>
                </div>
                <div
                    v-if="!schoolGoogleSchoolStaffId"
                    class="col-12"
                >
                    <div class="form-group px-3">
                        <div class="input-group">
                            <select
                                v-model="selectedSchoolStaffId"
                                class="custom-select form-control"
                            >
                                <option :value="0" />
                                <option
                                    v-for="(t, idx) in teachers"
                                    :key="`staff_${t.schoolStaffId}_${idx}`"
                                    :value="t.schoolStaffId"
                                >
                                    {{ t.googleEmail }}
                                </option>
                            </select>
                            <div class="input-group-append">
                                <template v-if="saving">
                                    <span
                                        class="input-group-text d-block"
                                        style="min-width: 44px;"
                                    >
                                        <i
                                            style="margin-top: 10px"
                                            class="kt-spinner kt-spinner--sm kt-spinner--primary d-block"
                                        />
                                    </span>
                                </template>
                                <template v-else-if="!saving">
                                    <button
                                        v-if="selectedSchoolStaffId"
                                        class="btn btn-success kt-font-bolder"
                                        @click.stop.prevent="addStudentsToGoogleCourse"
                                    >
                                        Continue
                                    </button>
                                    <span
                                        v-else
                                        class="input-group-text"
                                        @click.stop.prevent="showModal = false"
                                    >
                                        <i class="la la-close" />
                                    </span>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="alert alert-secondary"
                    role="alert"
                >
                    <div class="alert-icon">
                        <i class="flaticon-questions-circular-button kt-font-warning" />
                    </div>
                    <div class="alert-text">
                        In order to add students into the google classroom roster, the student must already have
                        a school managed email account, and that same email address must be associated with the student
                        in SyncGrades.
                    </div>
                </div>
            </div>
        </template>
        <template #modal-footer>
            <div class="row">
                <div class="col-12">
                    <button
                        type="button"
                        class="btn btn-label mr-2"
                        :class="[
                            bulkRosteringCompleted ? 'btn-label-secondary' : 'btn-label-danger',
                        ]"
                        @click.stop.prevent="close"
                    >
                        {{ !bulkRosteringCompleted ? 'Cancel Rostering' : 'Close' }}
                    </button>
                    <button
                        v-if="schoolGoogleSchoolStaffId && !saving"
                        type="button"
                        class="btn btn-label-success mr-2"
                        @click.stop.prevent="addStudentsToGoogleCourse(true)"
                    >
                        Start Rostering with Admin Credentials
                    </button>
                    <button
                        v-if="!saving"
                        type="button"
                        class="btn btn-label-primary"
                        @click.stop.prevent="addStudentsToGoogleCourse(false)"
                    >
                        Start Rostering
                    </button>
                </div>
            </div>
        </template>
    </b-modal>
</div>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import async from 'async';
import VueTagsInput from '@johmun/vue-tags-input';
import UserAvatar from '../components/UserAvatar.vue';
import StudentCourseAverage from '../components/StudentCourseAverage.vue';
import portfolioMixins from '../store/mixins/portfolioMixins';
import teacherMixins from '../store/mixins/teacherMixins';
import studentMixins from '../store/mixins/studentMixins';
import courseMixins from '../store/mixins/courseMixins';
import utilMixins from '../store/mixins/utilMixins';
import googleCourseMixins from '../store/mixins/googleCourseMixins';
import Types from '../store/Types';
import CourseSubHeader from './CourseSubHeader.vue';
import * as network from '../network';

export default {
    name: 'CourseRosters',
    components: {
        VueTagsInput,
        UserAvatar,
        StudentCourseAverage,
        CourseSubHeader,
    },
    mixins: [
        portfolioMixins,
        courseMixins,
        teacherMixins,
        googleCourseMixins,
        studentMixins,
        utilMixins,
    ],
    data() {
        return {
            selectedSchoolStaffId: 0,
            showModal: false,
            bulkRosteringCompleted: false,
            saving: false,
            rosterResults: [],
            key: 0,
            abortController: null,
            showAddStudent: false,
            newStudents: [],
            filter: '',
            sortBy: 'Last Name',
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            school: (state) => state.user.school,
        }),
        isGoogleDisabled() {
            const { school } = this;
            return ['District Managed', 'Disabled'].includes(school.googleSetting);
        },
        schoolTermMarkingPeriodId() {
            return this.$store.state.settings.schoolTermMarkingPeriodId;
        },
        schoolGoogleSchoolStaffId() {
            const { school, teachers } = this;
            const { googleAccountId } = school;
            const admin = teachers.find((t) => t.googleAccountId == googleAccountId) || null;
            if (!admin) return null;
            return admin.schoolStaffId;
        },
        rosterErrors() { return this.rosterResults.filter((r) => r.err); },
        rosterAdditions() { return this.rosterResults.filter((r) => !r.err); },
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        teacher() {
            return this.$_teacherMixins_getTeacherFromRoute();
        },
        googleCourse() {
            return this.$_googleCourseMixins_getGoogleCourseFromRoute();
        },
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        courseSections() {
            return this.$store.state.database.courseSections;
        },
        courseSection() {
            return this.course;
        },
        teachers() {
            return this.$_teacherMixins_getReducedTeachers();
        },
        students() {
            const { course, schoolTermMarkingPeriodId, sortBy } = this;
            if (!course) return [];
            const students = this.$_studentMixins_getStudentsForCourse(course);
            return students.map((s) => {
                const student = { ...s };
                student.markingPeriodAverages = student.markingPeriodAverages.filter((mp) => schoolTermMarkingPeriodId == null || mp.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId);
                return student;
            }).sort((a, b) => {
                if (sortBy === 'Last Name') {
                    if (a.lastName < b.lastName) return -1;
                    if (a.lastName > b.lastName) return 1;
                    return 0;
                }
                if (sortBy === 'First Name') {
                    if (a.firstName < b.firstName) return -1;
                    if (a.firstName > b.firstName) return 1;
                    return 0;
                }
                return 0;
            });
        },
        addableStudents() {
            const { students, newStudents, filter } = this;
            return this.$_studentMixins_getStudents().map((s) => ({
                text: `${s.lastName}, ${s.firstName}`,
                value: s.studentEnrollmentId,
            })).filter((student) => {
                const found = students.find((s) => s.studentEnrollmentId == student.value && !newStudents.includes(student.studentEnrollmentId)) || null;
                if (found) return false;
                if (!filter) return true;
                return student.text.toLowerCase().includes(filter.toLowerCase());
            });
        },
        missingStudents() {
            return this.students.filter((s) => !s.googleStudent);
        },
        title() {
            const route = this.$route;
            return route.meta.title || null;
        },
        icon() {
            const route = this.$route;
            return route.meta.icon || null;
        },
        canEditCourse() {
            return this.courseSection.canEditCourse || false;
        },
        classBidingPopulateGoogle() {
            return { 'disabled-link': (this.missingStudents.length == 0 || this.saving) && !this.canEditCourse };
        },
        canManageStudents() {
            const { user, course, school } = this;
            const { role } = school;
            const { userPermissions } = user;
            const { teachers } = course;

            if (['School Admin'].includes(role)) return true;

            return teachers.length > 0 && Boolean(teachers.find((t) => t.schoolStaffId == user.school.schoolStaffId)) && userPermissions.canManageCourses;
        },
    },
    watch: {
        '$route.params.extCourseSectionId': {
            handler() {
                this.key += 1;
            },
        },
        isPortfolioReady: {
            handler() {
                this.key += 1;
            },
        },
        schoolTermMarkingPeriodId() {
            this.key += 1;
        },
        courseSections: {
            handler() {
                this.key += 1;
            },
        },
    },
    methods: {
        close() {
            const { bulkRosteringCompleted, saving } = this;
            if (!bulkRosteringCompleted && saving) {
                const confirm = window.confirm('Do you want to cancel pending google rostering requests?');
                if (!confirm) return;
                this.hideModal();
            }
            this.hideModal();
        },
        hideModal() {
            const { abortController } = this;
            if (abortController) abortController.abort();
            this.saving = false;
            this.showModal = false;
            // refresh google tables on close
            this.$store.dispatch(Types.actions.REFRESH_GOOGLE_TABLES, (err2) => {
                if (err2) {
                    console.error(err2);
                }
            });
        },
        showGoogleRostersModal() {
            this.showModal = true;
        },
        addStudentsToGoogleCourse(useAdmin) {
            const {
                missingStudents, showError, schoolGoogleSchoolStaffId, teacher, teachers,
            } = this;
            if (missingStudents.length == 0) return showError('All students are already enrolled');
            if (this.saving) return;
            this.saving = true;
            this.bulkRosteringCompleted = false;

            this.rosterResults = [];

            const { schoolId, schoolTermId } = this.$store.state.user.school;
            const { googleCourseId } = this.googleCourse;
            if (schoolGoogleSchoolStaffId) {
                if (useAdmin) this.selectedSchoolStaffId = schoolGoogleSchoolStaffId;
                else this.selectedSchoolStaffId = teacher.schoolStaffId;
            }
            const schoolStaffId = this.selectedSchoolStaffId;
            if (!schoolStaffId) return showError('No school staff selected');

            let hasError = false;
            const v = this;
            v.abortController = new AbortController();

            async.eachOfLimit(missingStudents, 1, (s, idx, nextStudent) => {
                const params = {
                    abortController: v.abortController,
                    url: { schoolId, schoolTermId, googleCourseId },
                    body: {
                        schoolStaffId,
                        emailAddress: s.googleEmail,
                    },
                };
                if (!s.googleEmail) {
                    const student = { ...s };
                    student.err = 'No Google Email';
                    v.rosterResults.push(student);
                    return nextStudent();
                }
                network.google.addStudentToCourse(params, (err) => {
                    if (err) { hasError = true; }
                    const student = { ...s };
                    student.err = err;
                    v.rosterResults.push(student);
                    nextStudent();
                });
            }, () => {
                v.saving = false;
                v.bulkRosteringCompleted = true;
                v.abortController = null;
                if (hasError) showError('There were errors adding some students into the google classroom course');
            });
        },
        addStudentToCourse() {
            const v = this;
            if (v.saving || !v.newStudents.length) return;
            v.saving = true;

            const { school, course } = v;
            const { schoolId, schoolTermId } = school;
            const { courseSectionId } = course;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    courseSectionId,
                },
                body: {
                    studentEnrollmentIds: v.newStudents.map((s) => s.value),
                },
            };
            network.courseSectionStudents.addStudent(params, (err, res) => {
                v.saving = false;
                if (err) return v.showError('Unable to add student to course');
                const { courseSectionStudents } = res;
                if (!courseSectionStudents) return v.showError('Unable to add student to course');
                v.newStudents = [];
                v.$store.commit(Types.mutations.SET_DB_COURSE_SECTION_STUDENTS, courseSectionStudents);
            });
        },
        removeStudentFromCourse(student) {
            const v = this;
            if (v.saving || !student) return;
            v.saving = true;

            const { school, course } = v;
            const { schoolId, schoolTermId } = school;
            const { courseSectionId } = course;
            const { studentEnrollmentId } = student;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    courseSectionId,
                },
                body: {
                    studentEnrollmentId,
                },
            };
            network.courseSectionStudents.deleteStudent(params, (err, res) => {
                v.saving = false;
                if (err) return v.showError('Unable to remove student from course');
                const { status } = res;
                if (status !== 'deleted') return v.showError('Unable to remove student from course');
                v.$store.commit(Types.mutations.REMOVE_COURSE_SECTION_STUDENT, student);
            });
        },
    },
};
</script>

<style scoped>
div.log-container {
    height: 40vh;
    max-height: 20vh;
    overflow-y: auto;
}
.email-container {
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.btn-wide {
    white-space: nowrap;
}
.kt-widget__img_sg {
    border-radius: 50%;
    width: 90px;
    min-height: 90px;
}
.email-label {
    text-overflow: ellipsis;
    max-width: 200px;
    overflow: clip;
}
div.kt-widget__description span {
    width: 100%;
    text-overflow: clip;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    display: inline-block;
}
.disabled-link {
    pointer-events: none;
    color: gray;
    cursor: not-allowed;
    text-decoration: none;
  }
.top-left {
    position: absolute;
    top: 1px;
    left: 1px;
    z-index: 10;
}
.top-right {
    position: absolute;
    top: 1px;
    right: 1px;
    z-index: 10;
}
.vue-tags-input {
    width: 27.5vw !important;
}
</style>

<style>
.popover {
    max-width: 35vw !important;
    width: 35vw !important;
}

.vue-tags-input .ti-tag {
    position: relative;
    background: #ebde6e !important;
    color: #283944 !important;
}
</style>
